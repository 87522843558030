<template>
  <div id="app">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
 </div> 
</template>
<script>
 
  function abc()
    {
        alert("sss2");
    }
   

export default {
  name: 'App',
  data: function () {
    return {
      menu: false
    }
  }, 
}
</script>