<template>
  <div>
    <div v-if="popup2" class="popup">
      <span class="popup2">
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          @click="popup2 = false"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
        <img src="/images/dcs.png" />
        <ul class="pdv">
          <li>Carrefour</li>
          <li>Cora</li>
          <li>Fnac</li>
          <li>FUN</li>
          <li>Trafic</li>
          <li>Makro</li>
          <li>Supra Bazaar</li>
        </ul>
      </span>
    </div>

    <div v-if="popup" class="popup">
      <span>
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          @click="popup = false"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
        <img :src="`${dcs}`"
      /></span>
    </div>
    <iframe
      :src="`${uri}`"
      frameborder="0"
      style="overflow:hidden;height:100%;width:100%"
      width="100%"
    ></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      popup: false,
      popup2: false,
      uri: undefined,
      dcs: undefined,
    };
  },
  mounted() {


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const lang = urlParams.get("lang");

    if (lang) {
      if (lang === "nl") {
        this.uri = "https://odr.promo.dev/623db10350643900166a9ba4";
        this.dcs = "/images/dcs-nl.jpg";
      } else {
        this.uri = "https://odr.promo.dev/6232f540ba73ec0016fc0bdc";
        this.dcs = "/images/dcs-fr.jpg";
      }
    } else {
      var userLang = navigator.language || navigator.userLanguage;
      if (userLang === "nl-BE") {
        this.uri = "https://odr.promo.dev/623db10350643900166a9ba4";
        this.dcs = "/images/dcs-nl.jpg";
      } else {
        this.uri = "https://odr.promo.dev/6232f540ba73ec0016fc0bdc";
        this.dcs = "/images/dcs-fr.jpg";
      }
    }
    window.addEventListener("message", (event) => {
      if (event.data === "openImage") this.popup = true;
      if (event.data === "openPoints") this.popup2 = true;
      if (event.data === "openImagenl") {
         this.popup = true;
         this.dcs = "/images/dcs-nl.jpg";
      }
    });

  },
};
</script>
<style lang="scss">
body {
  background: black;
}

iframe {
  margin: 0 !important;
  padding: 0 !important;
  height: 99.7% !important;
  overflow: hidden;
}
.popup {
  position: absolute;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  background-color: rgba(111, 110, 106, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    display: block;
    width: 80%;
    position: relative;
    max-width: 1500px;
  }

  img {
    width: 100%;
  }

  svg {
    width: 2%;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}

.popup2 {
  background: white;
  height: 50%;
  width: 50%;
  max-width: 1200px !important;
  padding: 50px;
}

.pdv {
  
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  margin-top: 20px;

  li {
    width: 47%;
    background-color: rgb(223, 223, 219);
    border-radius: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
    font-family: 'Roboto' !important;
    @media screen and (max-width: 840px) {
          width: 100%;
    }
  }
}
</style>
